import { brandName } from '@lp-root/src/modules/constants';
import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

/**
 * seo meta-tags
 *
 * twitter: https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary
 * facebook: http://ogp.me
 *
 * test here:
 *  - facebook: https://developers.facebook.com/tools/debug/
 *  - twitter: https://cards-dev.twitter.com/validator
 *
 */
export interface SocialMediaSEOProps {
  /**
   * # twitter
   * A concise title for the related content. Platform specific behaviors:
   * - iOS, Android: Truncated to two lines in timeline and expanded Tweet
   * - Web: Truncated to one line in timeline and expanded Tweet
   *
   * # ogp
   * -  The title of your object as it should appear within the graph, e.g., "The Rock".
   */
  title: string;

  /**
   * # twitter
   * A URL to a unique image representing the content of the page. You should not use a generic image such as your website logo, author photo, or other image that spans multiple pages. Images for this Card support an aspect ratio of 1:1 with minimum dimensions of 144x144 or maximum of 4096x4096 pixels. Images must be less than 5MB in size. The image will be cropped to a square on all platforms. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an animated GIF will be used. SVG is not supported.
   *
   * # ogp
   * An image URL which should represent your object within the graph.
   * ex: "http://ia.media-imdb.com/images/rock.jpg"
   */
  image?: string;

  /**
   * # twitter
   * A description that concisely summarizes the content as appropriate for presentation within a Tweet. You should not re-use the title as the description or use this field to describe the general services provided by the website. Platform specific behaviors:
   * - iOS, Android: Not displayed
   * - Web: Truncated to three lines in timeline and expanded Tweet
   */
  description?: string;

  /**
   * # ogp
   *  The canonical URL of your object that will be used as its permanent ID in the graph, e.g., "http://www.imdb.com/title/tt0117500/".
   */
  canonicalUrl: string;

  /**
   * # twitter
   * A text description of the image conveying the essential nature of an image to users who are visually impaired. Maximum 420 characters.
   *
   * # ogp
   * A description of what is in the image (not a caption). If the page specifies an og:image it should specify og:image:alt.
   */
  imageAlt?: string;

  /** http://ogp.me/#no_vertical */
  article?: {
    /** datetime - When the article was first published. */
    published_time?: Date;
    /** datetime - When the article was last changed. */
    modified_time?: Date;
    /** datetime - When the article is out of date after. */
    expiration_time?: Date;
    /** profile array - Writers of the article. */
    author: string[];
    /** string - A high-level section name. E.g. Technology */
    section: string;
    /** string array - Tag words associated with this article. */
    tag: string[];
  };

  profile?: {
    /** string - A name normally given to an individual by a parent or self-chosen. */
    first_name: string;
    /** string - A name inherited from a family or marriage and by which the individual is commonly known. */
    last_name: string;
    /** string - A short unique string to identify them. */
    username: string;
    /** enum(male, female) - Their gender. */
    gender: 'male' | 'female';
  };
}

//

function areEqual(prevProps: SocialMediaSEOProps, nextProps: SocialMediaSEOProps) {
  const mainInfoEqual =
    prevProps.title === nextProps.title &&
    prevProps.image === nextProps.image &&
    prevProps.description === nextProps.description &&
    prevProps.canonicalUrl === nextProps.canonicalUrl &&
    prevProps.imageAlt === nextProps.imageAlt;
  if (!mainInfoEqual) return false;

  const articleEqual =
    prevProps.article === nextProps.article ||
    (prevProps.article &&
      nextProps.article &&
      (prevProps.article.published_time && prevProps.article.published_time.getTime()) ===
        (nextProps.article.published_time && nextProps.article.published_time.getTime()) &&
      (prevProps.article.modified_time && prevProps.article.modified_time.getTime()) ===
        (nextProps.article.modified_time && nextProps.article.modified_time.getTime()) &&
      (prevProps.article.expiration_time && prevProps.article.expiration_time.getTime()) ===
        (nextProps.article.expiration_time && nextProps.article.expiration_time.getTime()) &&
      prevProps.article.section === nextProps.article.section &&
      prevProps.article.tag.join() === nextProps.article.tag.join() &&
      prevProps.article.author.join() === nextProps.article.author.join());
  if (!articleEqual) return false;

  const profileEqual =
    prevProps.profile === nextProps.profile ||
    (prevProps.profile &&
      nextProps.profile &&
      prevProps.profile.first_name === nextProps.profile.first_name &&
      prevProps.profile.last_name === nextProps.profile.last_name &&
      prevProps.profile.username === nextProps.profile.username &&
      prevProps.profile.gender === nextProps.profile.gender);
  if (!profileEqual) return false;

  return true;
}

// eslint-disable-next-line react/display-name
export const SocialMediaSEO = React.memo((props: SocialMediaSEOProps) => {
  return (
    <StaticQuery
      query={graphql`{
  site {
    siteMetadata {
      facebookAppId
      siteUrl
    }
  }
  icon: file(relativePath: {eq: "icon.png"}) {
    childImageSharp {
      gatsbyImageData(width: 700, layout: FIXED)
    }
  }
}
`}
      render={(data) => {
        const fbAppId = data.site.siteMetadata.facebookAppId;
        const icon = `${data.site.siteMetadata.siteUrl}${data.icon.childImageSharp.gatsbyImageData.src}`;
        const image = props.image || icon;

        return (
          <Helmet>
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@vigilantesdosono" />
            <meta name="twitter:title" content={props.title} />
            {props.description && <meta name="twitter:description" content={props.description} />}
            <meta name="twitter:image" content={image} />
            {props.imageAlt && <meta name="twitter:image:alt" content={props.imageAlt} />}

            {/* # http://ogp.me/#metadata */}
            <meta property="og:type" content={getOgType(props)} />
            <meta property="og:title" content={props.title} />
            <meta property="og:url" content={props.canonicalUrl} />
            <meta property="og:image" content={image} />
            {props.description && <meta property="og:description" content={props.description} />}

            {props.imageAlt && <meta property="og:image:alt" content={props.imageAlt} />}
            <meta property="og:locale" content="pt_BR" />

            <meta property="og:site_name" content={brandName} />
            {fbAppId && <meta property="fb:app_id" content={fbAppId} />}

            {props.profile && <meta property="article:first_name" content={props.profile.first_name} />}
            {props.profile && <meta property="article:last_name" content={props.profile.last_name} />}
            {props.profile && <meta property="article:username" content={props.profile.username} />}
            {props.profile && <meta property="article:gender" content={props.profile.gender} />}

            {props.article && props.article.published_time && (
              <meta property="article:published_time" content={props.article.published_time.toISOString()} />
            )}
            {props.article && props.article.modified_time && (
              <meta property="article:modified_time" content={props.article.modified_time.toISOString()} />
            )}
            {props.article && props.article.expiration_time && (
              <meta property="article:expiration_time" content={props.article.expiration_time.toISOString()} />
            )}
            {props.article && <meta property="article:section" content={props.article.section} />}
            {props.article &&
              props.article.author &&
              props.article.author.map((author) => <meta property="article:author" key={author} content={author} />)}
            {props.article && props.article.tag && props.article.tag.map((tag) => <meta property="article:tag" key={tag} content={tag} />)}
          </Helmet>
        );
      }}
    />
  );
}, areEqual);

const getOgType = (props: SocialMediaSEOProps) => {
  if (props.article) return 'article';
  if (props.profile) return 'profile';

  return 'website';
};
