import { brandName, logoUrl } from '@lp-root/src/modules/constants';
import { normalizeUrl } from '@web/utils/url';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { JsonLd, JsonLdProps } from './json-ld.component';
import { SocialMediaSEO, SocialMediaSEOProps } from './social-media-seo.component';

/**
 * If your content is an article, you don't need to send jsonLd (just send the article data inside the socialMedia.article)
 */
export interface SEOProps extends JsonLdProps {
  robots?: string;
  socialMedia: SocialMediaSEOProps;
  keywords?: string;
}

export const SEO = (props: SEOProps) => {
  const fixedUrlProps = {
    ...props,
    socialMedia: {
      ...props.socialMedia,
      url: normalizeUrl(props.socialMedia.canonicalUrl),
    },
  };

  const mainInfo = React.useMemo(() => {
    // • Crie um título único por página
    // • Use a palavra-chave principal no início do título
    // • Este título precisa ser coeso com o conteúdo presente na página
    // • Coloque a sua marca no final do título (sempre que julgar a sua marca um diferencial)
    // • Evite colocar uma série de termos separados por vírgula
    // • Mantenha o título com até 63 caracteres
    let title = fixedUrlProps.socialMedia.title;
    if (title.length <= 42) {
      title += ` | Vigilantes do Sono`;
    }
    if (title.length < 47) {
      console.warn(`SEO -> small title`, title);
    }
    if (title.length > 63) {
      console.warn(`SEO -> big title`, title);
    }

    // 1. Para uma pessoa, a meta description precisa ser chamativa, interessante, informativa, curiosa e com um toque de Call-to-Action.
    // 2. Pode, mas não deve, conter mais do que 160 caracteres.
    const description = fixedUrlProps.socialMedia.description || '';
    if (description.length < 120) {
      console.warn(`SEO -> small description`, description);
    }
    if (description.length > 160) {
      console.warn(`SEO -> big description`, description);
    }
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="language" content="Portuguese" />
        {props.keywords && <meta name="keywords" content={props.keywords} />}
        <title>{title}</title>
        <meta name="image" content={fixedUrlProps.socialMedia.image} />
        {description && <meta name="description" content={description} />}
        <link rel="canonical" href={fixedUrlProps.socialMedia.url} />
        {/* https://developers.google.com/search/reference/robots_meta_tag?hl=pt-br#robotsmeta */}
        {fixedUrlProps.robots && <meta name="robots" content={fixedUrlProps.robots} />}
      </Helmet>
    );
  }, [
    fixedUrlProps.socialMedia.title,
    fixedUrlProps.socialMedia.image,
    fixedUrlProps.socialMedia.description,
    fixedUrlProps.socialMedia.url,
  ]);

  return (
    <>
      <SocialMediaSEO {...props.socialMedia} />
      {props.jsonld && <JsonLd jsonld={props.jsonld} />}
      {props.socialMedia.article && <JsonLd jsonld={getArticleJsonld(props, { logoUrl, name: brandName })} />}
      {mainInfo}
    </>
  );
};

/**
 * https://developers.google.com/search/docs/data-types/article#logo_guidelines
 */
interface PublisherData {
  name: string;
  logoUrl: string;
}

const getArticleJsonld = (props: SEOProps, publisherData: PublisherData) => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': props.socialMedia.canonicalUrl,
  },
  articleSection: props.socialMedia.article.section,
  headline: props.socialMedia.title,
  image: [props.socialMedia.image],
  datePublished: props.socialMedia.article.published_time && props.socialMedia.article.published_time.toISOString(),
  dateModified: props.socialMedia.article.modified_time && props.socialMedia.article.modified_time.toISOString(),
  author: {
    '@type': 'Person',
    name: props.socialMedia.article.author.join(','),
  },
  publisher: {
    '@type': 'Organization',
    name: publisherData.name,
    logo: {
      '@type': 'ImageObject',
      url: publisherData.logoUrl,
    },
  },
  description: props.socialMedia.description,
});
