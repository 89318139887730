import { performHourSum } from '@global/utils/date';
import { GetUpAndGoToBedSleepRestrictionMargins } from '@global/utils/domain/sleep-restriction';
import { addMinutes } from 'date-fns';
import { SleepRestrictionEvaluation } from '../pages/chatfuel/relatorio-restricao-do-sono';

export interface FollowedRecommendedReturn {
  didSleepAtRightTime: SleepRestrictionEvaluation;
  didGetUpAtRightTime: SleepRestrictionEvaluation;
}

/**
 * This function verifies if the user followed the recommended sleep/getUp schedule
 * calculated by the algorithm
 *
 * @param recommendedSleepDate
 * @param recommendedGetUpDate
 * @param sleepDate
 * @param getUpDate
 */
export function hasfollowedRecommended(
  recommendedSleepDate: Date,
  recommendedGetUpDate: Date,
  sleepDate: Date,
  getUpDate: Date
): FollowedRecommendedReturn {
  const didSleepAtRightTime = hasFollowedSleepTime(recommendedSleepDate, sleepDate);
  const didGetUpAtRightTime = hasFollowedGetUpTime(recommendedGetUpDate, getUpDate);

  return { didSleepAtRightTime, didGetUpAtRightTime };
}

/**
 * This function checks if the user got up at the right time, considering the
 * interval defined as a business rule
 *
 * @param recommendedGetUpDate
 * @param getUpDate
 * @returns
 */

function hasFollowedGetUpTime(recommendedGetUpDate: Date, getUpDate: Date): SleepRestrictionEvaluation {
  const recommendedGetUpDateMin = addMinutes(recommendedGetUpDate, -GetUpAndGoToBedSleepRestrictionMargins.beforeRecommendedTimeToGetUp);
  const recommendedGetUpDateMax = addMinutes(recommendedGetUpDate, GetUpAndGoToBedSleepRestrictionMargins.afterRecommendedTimeToGetUp);
  const didGetUpAtRightTime =
    recommendedGetUpDateMin <= getUpDate && getUpDate <= recommendedGetUpDateMax
      ? SleepRestrictionEvaluation.Right
      : SleepRestrictionEvaluation.Wrong;

  return didGetUpAtRightTime;
}

/**
 * This function checks if the user slept at the right time, considering the
 * interval defined as a business rule
 *
 * @param recommendedSleepDate
 * @param sleepDate
 */
function hasFollowedSleepTime(recommendedSleepDate: Date, sleepDate: Date): SleepRestrictionEvaluation {
  const recommendedSleepDateMin = addMinutes(recommendedSleepDate, -GetUpAndGoToBedSleepRestrictionMargins.beforeRecommendedTimeToSleep);
  const recommendedSleepDateMax = addMinutes(recommendedSleepDate, GetUpAndGoToBedSleepRestrictionMargins.afterRecommendedTimeToSleep);

  const didSleepAtRightTime =
    recommendedSleepDateMin <= sleepDate && sleepDate <= recommendedSleepDateMax
      ? SleepRestrictionEvaluation.Right
      : SleepRestrictionEvaluation.Wrong;
  return didSleepAtRightTime;
}

interface TooltipTextReturn {
  tooltipRecommendedSleepTime: string;
  tooltipRecommendedGetUpTime: string;
}

/**
 * This function get the tooltip text (at column title)
 * that should explain the intervals for the user.
 *
 * @param recommendedSleepHour
 * @param recommendedGetUpHour
 * @returns
 */
export function getTooltipText(recommendedSleepHour: string, recommendedGetUpHour: string): TooltipTextReturn {
  const recommendedSleepMin = performHourSum(-GetUpAndGoToBedSleepRestrictionMargins.beforeRecommendedTimeToSleep, recommendedSleepHour);
  const recommendedSleepMax = performHourSum(GetUpAndGoToBedSleepRestrictionMargins.afterRecommendedTimeToSleep, recommendedSleepHour);

  const recommendedGetUpMin = performHourSum(-GetUpAndGoToBedSleepRestrictionMargins.beforeRecommendedTimeToGetUp, recommendedGetUpHour);
  const recommendedGetUpMax = performHourSum(GetUpAndGoToBedSleepRestrictionMargins.afterRecommendedTimeToGetUp, recommendedGetUpHour);

  const tooltipRecommendedSleepTime = `O horário recomendado para você dormir é entre ${recommendedSleepMin} e ${recommendedSleepMax}`;
  const tooltipRecommendedGetUpTime = `O horário recomendado para você acordar é entre ${recommendedGetUpMin} e ${recommendedGetUpMax}`;

  return { tooltipRecommendedSleepTime, tooltipRecommendedGetUpTime };
}
