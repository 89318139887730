import { NativeHelper } from './native.helper';
import MessengerUserInfoDataSource from './messenger-user-info.datasource';

export class UserIdDataSource {
  static getId = async (): Promise<string> => {
    const userIdFromNative = NativeHelper.userId;
    if (userIdFromNative) {
      return userIdFromNative;
    } else {
      console.log('DIARY_DEBUG: user-id.datasource.ts ~ line 11 ~ UserIdDataSource ~ getId= ~ userInfo');
      const userInfo = await MessengerUserInfoDataSource.getUserInfo();
      return userInfo.psid;
    }
  };
}
