import { GatsbyButton } from '@components/atm.button/button.component';
import { parseToDate } from '@global/utils/date/parse-to-date';
import { parseDateToShortString, parseToYYYYMMDD } from '@global/utils/date/parse-to-string';
import { performHourSum } from '@global/utils/date/perform-hour-sum';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { Query_Root, Sleep_Restriction } from '@global/utils/remote-graphql-types';
import { Body, Col, FaIcon, Grid, H1, LightColor, Row, Separator } from '@web/atomic';
import { StickButtonWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { TextShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { ShimmerInput } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { Table, TD, TH, THead, TR } from '@web/atomic/legacy/mol.table';
import { TooltipProps } from '@web/atomic/legacy/obj.globals/tooltip';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import { UserIdDataSource } from '@web/data/user-id.datasource';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { getCanonicalUrl } from '@web/utils/url';
import { addDays, differenceInDays } from 'date-fns';
import { graphql } from 'gatsby';
import * as React from 'react';
import { SEO } from '../../components/legacy/mol.seo/seo.component';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { LastSleepRestrictionQuery } from '../../data/graphql/hasura/last-sleep-restriction.query.hasura';
import { SleepDiaryForSleepRestrictionQuery } from '../../data/graphql/hasura/sleep-diary-for-sleep-restriction.query.hasura';
import { buildChatfuelBroadcastRequest } from '../../data/request-utils/redirect.request';
import { PageProps } from '../../utils/local-types';
import { getTooltipText, hasfollowedRecommended } from '../../utils/sleep-restriction.utils';

const RelatorioRestricaoDoSonoPage: React.FunctionComponent<PageProps> = (props) => {
  const { data, error, loading } = useQueryCustom(LastSleepRestrictionQuery);
  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Relatório da restrição do sono',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12}>
            <H1>Relatório da restrição do sono</H1>
            <Body>
              Confira como foi sua semana e depois clique em &quot;Continuar&quot;. <br /> ps: Você pode clicar nas linhas para ver mais
              detalhes daquele dia.
            </Body>
          </Col>
        </Row>
        <LoadingState loading={loading} error={!!error} data={data && data.sleep_restriction && data.sleep_restriction.length > 0}>
          {/* TODO: UI - ajustar loading */}
          <LoadingState.Shimmer>
            <>
              {Array(3)
                .fill(1)
                .map((_item, index) => (
                  <Row mt mb key={index}>
                    <Col xs={12} sm={12} md={7} lg={7}>
                      <ShimmerInput />
                      <Separator />
                    </Col>
                  </Row>
                ))}
            </>
          </LoadingState.Shimmer>
          <LoadingState.NoData>
            <Placeholder
              icon={<FaIcon.Table size="9x" />}
              title={'Nenhuma restrição do sono'}
              description={`Que estranho, parece que você não iniciou nenhuma restrição do sono. Se você já iniciou alguma, por favor, entre em contato conosco para te ajudarmos.`}
            >
              <GatsbyButton onClick={close} loading={waitCloseLoading}>
                Combinado!
              </GatsbyButton>
            </Placeholder>
          </LoadingState.NoData>
          <LoadingState.Error>
            <Placeholder
              icon={<FaIcon.FlashAlert size="9x" />}
              title={'Erro ao pegar seus dados'}
              description="Tente novamente mais tarde"
            />
          </LoadingState.Error>
          {data && data.sleep_restriction && data.sleep_restriction[0] && (
            <Row mt mb>
              <Col xs={12}>
                <SleepRestrictionTable data={data.sleep_restriction[0]} />
              </Col>
            </Row>
          )}
        </LoadingState>
      </Grid>
    </WebviewLayout>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ISleepRestrictionTableProps {
  data: Sleep_Restriction;
}

const SleepRestrictionTable: React.FunctionComponent<ISleepRestrictionTableProps> = (props) => {
  const { data, error, loading } = useQueryCustom(SleepDiaryForSleepRestrictionQuery, {
    variables: { date: props.data && props.data.start_date },
  });

  const startDate = parseToDate(props.data.start_date);
  const lastDate = new Date(data && data.sleep_diary && data.sleep_diary.length > 0 && data.sleep_diary[data.sleep_diary.length - 1].date);
  const daysCount = differenceInDays(lastDate, startDate);
  const dates = Array(daysCount > 0 ? daysCount : 0)
    .fill(startDate)
    .map((_item, index) => addDays(startDate, index + 1));
  const recommendedSleepHour = props.data.hour_to_sleep.slice(0, 5);
  const recommendedGetUpHour = props.data.desired_wake_up.slice(0, 5);
  const { tooltipRecommendedSleepTime, tooltipRecommendedGetUpTime } = getTooltipText(recommendedSleepHour, recommendedGetUpHour);

  const viewModel = dates.map((item) => mapToViewModel(loading, item, data, recommendedSleepHour, recommendedGetUpHour));

  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const [post, { loading: closeLoading }] = usePostUsingMessenger<ChatfuelBroadcastRequest>({
    onSuccess: close,
    url: PostUrl.RedirectUser,
  });
  const handleClose = async () => {
    const length = viewModel.length;
    const morningGrade = viewModel.filter((item) => item.wake === SleepRestrictionEvaluation.Right).length / length;
    const nightGrade = viewModel.filter((item) => item.sleep === SleepRestrictionEvaluation.Right).length / length;
    const finalGrade = (morningGrade + nightGrade) / 2;
    const minGrade = 0.65;
    console.log('DIARY_DEBUG: relatorio-restricao-do-sono.tsx ~ line 131 ~ handleClose ~ userId');
    const userId = await UserIdDataSource.getId();
    const request = buildChatfuelBroadcastRequest(userId, 'sleep_restriction_other_s2', {
      'global-sleep_restriction_start': props.data.start_date,
      'global-sleep_restriction_grade': finalGrade > minGrade,
      'global-sleep_restriction_morning_grade': morningGrade > minGrade,
      'global-sleep_restriction_night_grade': nightGrade > minGrade,
    });
    return post(request);
  };

  if (!viewModel || viewModel.length === 0) {
    return (
      <Placeholder
        icon={<FaIcon.Table size="9x" />}
        title={'Nenhum dado para a restrição de sono atual'}
        description={`Você iniciou um novo ciclo de restrição de sono, mas ainda não há nenhum diário para ser analisado.`}
      >
        <GatsbyButton onClick={close}> Continuar </GatsbyButton>
      </Placeholder>
    );
  }
  if (error) {
    return (
      <Placeholder icon={<FaIcon.FlashAlert size="9x" />} title={'Erro ao pegar seus dados'} description="Tente novamente mais tarde" />
    );
  }

  return (
    <>
      <Table>
        <THead>
          <TR>
            <TH textAlign="left">Data</TH>
            <TH
              className="microtip"
              role="tooltip"
              data-microtip-position="bottom"
              aria-label={tooltipRecommendedSleepTime}
              textAlign="right"
            >
              Dormiu ± {recommendedSleepHour} <FaIcon.Question />
            </TH>
            <TH
              className="microtip"
              role="tooltip"
              data-microtip-position="bottom"
              aria-label={tooltipRecommendedGetUpTime}
              textAlign="right"
            >
              Acordou ± {recommendedGetUpHour} <FaIcon.Question />
            </TH>
          </TR>
        </THead>
        {viewModel.map((view) => {
          const tooltipProps: TooltipProps = {
            className: 'microtip',
            role: 'tooltip',
            'data-microtip-size': 'small',
            'aria-label': view.tip,
          };

          return (
            <TR key={view.date}>
              <TD {...tooltipProps} data-microtip-position="right" textAlign="left">
                {view.date}
              </TD>
              <TD {...tooltipProps} data-microtip-position="right" textAlign="right">
                {mapSleepRestrictionEvaluationToView(view.sleep)}
              </TD>
              <TD {...tooltipProps} data-microtip-position="left" textAlign="right">
                {mapSleepRestrictionEvaluationToView(view.wake)}
              </TD>
            </TR>
          );
        })}
      </Table>
      {!loading && (
        <StickButtonWrapper>
          <GatsbyButton onClick={handleClose} expanded loading={closeLoading || waitCloseLoading}>
            Continuar
          </GatsbyButton>
        </StickButtonWrapper>
      )}
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

export enum SleepRestrictionEvaluation {
  Right,
  Wrong,
  Empty,
  Loading,
}

const mapToViewModel = (loading: boolean, item: Date, data: Query_Root, recommendedSleepHour: string, recommendedGetUpHour: string) => {
  const date = parseDateToShortString(item);
  if (loading) {
    return { date, sleep: SleepRestrictionEvaluation.Loading, wake: SleepRestrictionEvaluation.Loading };
  }

  const stringDate = parseToYYYYMMDD(item);
  const itemData = data && data.sleep_diary && data.sleep_diary.find((entry) => entry.date === stringDate);
  if (!itemData) {
    return { date, sleep: SleepRestrictionEvaluation.Empty, wake: SleepRestrictionEvaluation.Empty, tip: 'Dia não preenchido' };
  }

  const [recommendedSleepDate, recommendedGetUpDate] = mapHourToDate(stringDate, recommendedGetUpHour, recommendedSleepHour);
  const getUpTime = performHourSum(itemData.timezone * 60, itemData.get_up);
  const sleepTime = performHourSum(itemData.timezone * 60, itemData.go_sleep);
  const [sleepDate, getUpDate] = mapHourToDate(stringDate, getUpTime, sleepTime);

  const { didSleepAtRightTime, didGetUpAtRightTime } = hasfollowedRecommended(
    recommendedSleepDate,
    recommendedGetUpDate,
    sleepDate,
    getUpDate
  );

  const tip = `Dormiu: ${sleepTime}
Acordou: ${getUpTime}`;
  return { date, tip, sleep: didSleepAtRightTime, wake: didGetUpAtRightTime };
};

/**
 *
 * @param getUpDate yyyy-MM-dd
 * @param getUpTime HH:mm
 * @param sleepTime HH:mm
 */
const mapHourToDate = (getUpDate: string, getUpTime: string, sleepTime: string) => {
  // Why replace? https://stackoverflow.com/questions/4310953/invalid-date-in-safari
  const date = getUpDate.replace(/-/g, '/');

  const getUp = parseToDate(`${date} ${getUpTime}`);
  let sleep = parseToDate(`${date} ${sleepTime}`);

  if (sleep > getUp) {
    sleep = addDays(sleep, -1);
  }
  return [sleep, getUp];
};

////////////////////////////////////////////////////////////////////////////////////////////////////
const mapSleepRestrictionEvaluationToView = (evaluation: SleepRestrictionEvaluation) => {
  switch (evaluation) {
    case SleepRestrictionEvaluation.Empty:
      return <FaIcon.Square key="sleepEmpty" />;
    case SleepRestrictionEvaluation.Loading:
      return <TextShimmerBoxStyled key="sleepLoading" height="15px" width={'30%'} />;
    case SleepRestrictionEvaluation.Right:
      return <FaIcon.Check style={{ color: LightColor.Success }} />;
    case SleepRestrictionEvaluation.Wrong:
      return <FaIcon.Close style={{ color: LightColor.Alert }} />;
    default:
      return null;
  }
};

export default RelatorioRestricaoDoSonoPage;

export const query = graphql`
  query RelatorioRestricaoDoSonoPage {
    site {
      ...SiteUrl
    }
  }
`;
