import gql from 'graphql-tag'

export const SleepDiaryForSleepRestrictionQuery = gql`
  query SleepDiaryForSleepRestrictionQuery($date: date!) {
    sleep_diary(order_by: { date: asc }, where: { date: { _gt: $date } }) {
      id
      date
      get_up
      go_sleep
      timezone
    }
  }
`
