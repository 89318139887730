import gql from 'graphql-tag';

export const LastSleepRestrictionQuery = gql`
  query LastSleepRestrictionQueryLandingPage {
    sleep_restriction(limit: 1, order_by: { start_date: desc }) {
      before_eficiency
      before_fill_count
      before_time_in_bed
      before_time_sleep
      desired_wake_up
      hour_to_sleep
      new_time_in_bed
      start_date
      user_id
    }
  }
`;
